@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  background-color: #ffffff;
}

h1 {
  /* font-size: 2.4rem; */
  color: #3b3d80;
  text-align: center;
  justify-content: space-between;
}

h2 {
  /* font-size: 1.5rem; */
  color: #2c292b;
  text-align: center;
}

h3 {
  text-align: center;
  /* font-size: xx-large; */
  font-weight: bold;
}

p {
  font-size: 1rem;
  text-align: justify;
  text-justify: inter-word;
}
